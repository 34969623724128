import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../../components/SEO';
import Header from '../../../components/Header';
import ContentRow from '../../../components/ContentRow';
import { commonHeaderColor } from '../../../util';


const Page = ({ data }) => (
  <>
    <SEO
      title="Finden Sie eine Verkaufsstelle schnell und einfach"
      description="Jeder kann auf diese sichere Zahlungsmethode zugreifen, indem er zu einem örtlichen Kiosk, Supermarkt, einer Tankstelle oder anderen vertrauenswürdigen Neosurf-Händlern geht."
      alternateLanguagePages={[
        {
          path: 'buy-neosurf/point-of-sale',
          language: 'en'
        },
        {
          path: 'acheter-neosurf/point-de-vente',
          language: 'fr'
        },
        {
          path: 'buy-neosurf/verkaufsstelle',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://bit.ly/2AlU6Ch">
            <Img fluid={data.myneosurf.childImageSharp.fluid} alt="MyNeoSurf Logo" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="smaller h3 smaller">Finden Sie eine Verkaufsstelle</h1>
            <p className="textColorDark">Lokalisieren Sie Ihre Neosurf-Verkaufsstelle mühelos!</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <p className="textColorDark">Suchen Sie nach einem Neosurf-Gutschein, den Sie lieber persönlich kaufen möchten? Das Finden einer Verkaufsstelle ist schnell und einfach, da es tausende Standorte gibt, an denen Sie Neosurf-Gutscheine erwerben können. Jeder kann auf diese sichere Zahlungsmethode zugreifen, indem er zu einem örtlichen Kiosk, Supermarkt, einer Tankstelle oder anderen vertrauenswürdigen Neosurf-Händlern geht.</p>
      <p className="textColorDark">Der erste Schritt zum Finden einer Verkaufsstelle ist die Nutzung des Online-Locator-Tools auf der Neosurf-Website. Mit diesem Tool können Sie autorisierte Händler suchen, indem Sie Ihre Stadt, Postleitzahl oder Ihren aktuellen Standort eingeben. Innerhalb weniger Sekunden erhalten Sie eine Liste von nahegelegenen Verkaufsstellen, bei denen Sie Neosurf-Gutscheine erhalten können. Das Locator-Tool liefert auch zusätzliche Informationen wie Öffnungszeiten und Kontaktdaten, was den Erwerb der Gutscheine noch einfacher macht.</p>
      <p className="textColorDark">Für Vielreisende, die physische Käufe bevorzugen, ist der Zugang zu einer Neosurf-Verkaufsstelle in Ihrer Nähe äußerst praktisch. Da Neosurf weit verbreitet ist, besteht eine hohe Wahrscheinlichkeit, dass Sie einen Neosurf-Händler in Ihrer Nähe finden, egal ob Sie zu Hause sind oder eine neue Stadt erkunden. Um einen Gutschein zu kaufen, gehen Sie einfach in ein Geschäft, fragen nach einem Neosurf-Gutschein mit dem gewünschten Betrag und bezahlen Sie mit Bargeld oder Karte.</p>
      <p className="textColorDark">Der Kauf vor Ort bietet einen großen Vorteil für diejenigen, die Wert auf Privatsphäre und Sicherheit legen. Mit Neosurf müssen Sie keine persönlichen Daten oder Finanzinformationen angeben, wenn Sie einen Kauf tätigen. Dies macht es zu einer idealen Option für alle, die Bedenken hinsichtlich ihrer Online-Privatsphäre haben.</p>
      <p className="textColorDark">Nach dem Kauf eines Neosurf-Gutscheins erhalten Sie einen gedruckten Beleg mit einem einzigartigen Code. Diesen Code verwenden Sie, um Online-Zahlungen bei Anbietern zu tätigen, die Neosurf-Gutscheine akzeptieren. Die Nutzung des Codes ist sehr einfach: Sie müssen ihn lediglich an der Kasse eingeben, nachdem Sie Neosurf als Zahlungsmethode ausgewählt haben, und die Transaktion wird sofort verarbeitet. Egal, ob Sie Ihr Gaming-Konto aufladen, online einkaufen oder einen Service abonnieren – Neosurf macht es unglaublich einfach und vor allem sicher.</p>
      <p className="textColorDark">Die Zahl der Neosurf-Verkaufsstellen wächst ständig, sodass Sie sich darauf verlassen können, einen Händler in Ihrer Nähe zu finden. Mit der Wahl eines vertrauenswürdigen physischen Anbieters genießen Sie die Sicherheit, die eine weltweit anerkannte Zahlungsmethode bietet. Bereit, einen Gutschein zu kaufen? Nutzen Sie den Neosurf-Store-Locator, um die nächstgelegene Verkaufsstelle zu finden, und beginnen Sie, die unglaublichen Vorteile von Neosurf zu genießen!</p>
    </ContentRow>
  </>
)

export default Page;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    myneosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/myneosurf-header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
